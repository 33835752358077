<template>
	<div class="flex flex-col flex-1 px-8 py-10">
		<Form ref="loginForm" v-slot="{ errors, values }" :validation-schema="validationSchema" :initial-values="initialValues" @submit="handleSubmit">
			<div class="flex flex-col sm:flex-row items-center">
				<img src="@/assets/images/this-month-theme.png" alt="This month theme" class="w-246px sm:mr-60px" />
				<h2 class="flex flex-1 font-bold text-33px-sparse text-asp-pink">【 {{ postTheme || 'なし' }} 】</h2>
			</div>
			<div class="flex items-center justify-center mt-8 lg:px-20">
				<img :src="postDetail.post_img" alt="Post Image" class="w-full" />
			</div>
			<div class="mt-8">
				<label class="block mb-2 font-bold text-18px">タイトル（絵のなまえ）</label>
				<Field name="title" type="text" class="w-full px-4 py-2 border border-asp-black focus:outline-none" maxlength="20" />
				<p class="text-red-500">{{ errors.title }}</p>
			</div>
			<div class="mt-8">
				<label class="block mb-2 font-bold text-18px">
					メッセージ（絵のせつめい）
					<span :class="(values.message || '').length > 100 ? 'text-red-500' : ''" class="text-base ml-4"> {{ (values.message || '').length }} / 100 </span>
				</label>
				<Field name="message" as="textarea" class="w-full px-4 py-2 border border-asp-black h-140px focus:outline-none" maxlength="100" />
				<p class="text-red-500">{{ errors.message }}</p>
			</div>
			<div class="mt-8">
				<label class="block mb-2 font-bold text-18px">※注意事項（保護者の皆様へ）</label>
				<div class="w-full px-4 py-2 border border-asp-black">
					<p class="text-14px">
						◆投稿前の注意 <br />
						1.タイトルは必須項目となります。必ず入力して下さい。<br />
						2.投稿する絵には必ずどこかに「スマイルマーク」を描いて下さい。<br />
						3.明るい場所で、絵が切れないように撮影・投稿して下さい。<br />
						4.投稿後に削除・再投稿も可能です。<br />
						5.投稿した絵はマイページに保存されます。<br />
						6.投稿された絵や絵の一部は、あすたーとぴーすのホームページやパンフレット等にも掲載させていただく場合がございます。<br />
						7.事前告知なく収益目的で使用する事はしません。
						<br />
						<br />
						◆禁止事項 <br />
						1.著作権侵害等、アニメキャラクター等が特定出来る投稿 <br />
						2.個人情報が分かる投稿 <br />
						3.誹謗中傷・公的に問題となる投稿 <br />
						4.動画・音声の投稿。（現在は自分で描いた絵のみ投稿出来ます。）<br />
						5.他のお友だちの絵を許可無く使用・二次利用する事 <br />
						6.営業・宣伝・広告・勧誘等、営利目的の投稿。<br />
						以上の投稿は、発見され次第ご本人様の許可なく非公開又は削除致します。<br />
						また、非公開・削除の度合いによっては、アカウントを停止する場合もございます。<br />
						予めご了承ください。<br />
					</p>
				</div>
			</div>
			<div class="flex justify-center mt-22px">
				<button type="submit" class="focus:outline-none relative" :class="isSubmittingForm ? 'cursor-not-allowed' : ''">
					<img src="@/assets/images/post-submit-button.png" alt="Submit button" class="w-300px focus:outline-none" />
					<loading-animate-icon v-if="isSubmittingForm" class="absolute w-6 top-7 left-8" />
				</button>
			</div>
			<div class="flex justify-center mt-4">
				<router-link :to="{ name: 'puzzle' }" class="font-bold underline text-18px text-asp-light-gray">
					やめる
				</router-link>
			</div>
		</Form>
	</div>
</template>

<script>
import { markRaw } from 'vue';
import { object, string } from 'yup';
import { Field, Form } from 'vee-validate';
import dayjs from 'dayjs';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import loadingAnimateIcon from '@/assets/icons/LoadingAnimateIcon.vue';
import { getFieldValue } from '@/apis/utils';
import { getNowDate } from '@/services/utils';

export default {
	components: {
		loadingAnimateIcon,
		Form,
		Field
	},
	data() {
		const validationSchema = markRaw(
			object().shape({
				title: string()
					.typeError('タイトルには、文字を指定してください。')
					.test('length', 'タイトルは、20文字以下入力してください。', title => title.length <= 20)
					.required('タイトルを入力してください。')
					.test('ngWord', 'タイトルに禁止ワードが含まれているため、投稿できません。', title => {
						let ngWordErrorCount = 0;
						// eslint-disable-next-line no-restricted-syntax
						for (const ngWord of this.ngWordList) {
							if (title.search(ngWord) >= 0) ngWordErrorCount += 1;
						}
						return ngWordErrorCount === 0;
					}),
				message: string()
					.typeError('メッセージには、文字を指定してください。')
					.test('length', 'メッセージは、100文字以下入力してください。', message => message.length <= 100)
					.test('ngWord', 'メッセージに禁止ワードが含まれているため、投稿できません。', message => {
						let ngWordErrorCount = 0;
						// eslint-disable-next-line no-restricted-syntax
						for (const ngWord of this.ngWordList) {
							if (message.search(ngWord) >= 0) ngWordErrorCount += 1;
						}
						return ngWordErrorCount === 0;
					})
			})
		);

		return {
			postDetail: {},
			isFetchingPostDetail: false,
			postTheme: null,
			isFetchingPostTheme: false,
			ngWordList: [],
			isFetchingNGWord: false,
			isSubmittingForm: false,
			validationSchema,
			initialValues: {
				title: '',
				message: ''
			}
		};
	},
	mounted() {
		this.handleFetchPostDetail();
		this.handleFetchPostTheme();
		this.handleFetchNGWord();
	},
	methods: {
		handleFetchPostDetail() {
			try {
				if (this.isFetchingPostDetail) return;
				this.isFetchingPostDetail = true;

				const currentUserObject = JSON.parse(localStorage.getItem('signedInUser'));

				firebase
					.firestore()
					.collection('d_post')
					.doc(this.$route.params.id ?? '')
					.get()
					.then(documentSnapshot => {
						if (!documentSnapshot.exists || documentSnapshot.data().delete_flag === 1 || documentSnapshot.data().user_id !== currentUserObject.id) {
							this.isFetchingPostDetail = false;
							this.$router.push({ name: 'error_404' });
						}

						this.postDetail = {
							...documentSnapshot.data(),
							id: documentSnapshot.id
						};

						this.initialValues = {
							title: this.postDetail.post_title,
							message: this.postDetail.post_message
						};

						this.isFetchingPostDetail = false;
					})
					.catch(error => {
						this.$toast('エラーが発生しました。', {
							type: 'error'
						});
						this.isFetchingPostDetail = false;
					});
			} catch (error) {
				this.$toast('エラーが発生しました。', {
					type: 'error'
				});
				this.isFetchingPostDetail = false;
			}
		},
		handleFetchPostTheme() {
			try {
				if (this.isFetchingPostTheme) return;
				const nowDateTime = getNowDate();
				this.isFetchingPostTheme = true;

				firebase
					.firestore()
					.collection('m_theme')
					.where('theme_period_to', '>=', nowDateTime)
					.where('delete_flag', '==', 0)
					.get()
					.then(querySnapshot => {
						if (querySnapshot.empty) return;
						const themeList = querySnapshot.docs.filter(documentSnapshot => {
							return documentSnapshot.data().theme_period_from <= nowDateTime;
						});
						if (themeList.length > 0) {
							this.postTheme = themeList[0].data().theme;
						}
						this.isFetchingPostTheme = false;
					})
					.catch(error => {
						this.$toast('エラーが発生しました。', {
							type: 'error'
						});
						this.isFetchingPostTheme = false;
					});
			} catch (error) {
				this.$toast('エラーが発生しました。', {
					type: 'error'
				});
				this.isFetchingPostTheme = false;
			}
		},
		handleFetchNGWord() {
			try {
				if (this.isFetchingNGWord) return;
				this.isFetchingNGWord = true;

				firebase
					.firestore()
					.collection('m_ng_word')
					.get()
					.then(querySnapshot => {
						if (querySnapshot.empty) return;
						this.ngWordList = querySnapshot.docs.map(documentSnapshot => {
							return documentSnapshot.data().ng_word;
						});
						this.isFetchingNGWord = false;
					})
					.catch(error => {
						this.$toast('エラーが発生しました。', {
							type: 'error'
						});
						this.isFetchingNGWord = false;
					});
			} catch (error) {
				this.$toast('エラーが発生しました。', {
					type: 'error'
				});
				this.isFetchingNGWord = false;
			}
		},
		async handleSubmit(values) {
			if (this.isSubmittingForm) return;
			this.isSubmittingForm = true;

			try {
				await firebase
					.firestore()
					.collection('d_post')
					.doc(this.postDetail.id)
					.update({
						post_title: values.title,
						post_message: values.message,
						updated_at: getFieldValue().serverTimestamp()
					});

				const puzzlePieceDocumentSnapshot = await firebase
					.firestore()
					.collection('m_puzzle')
					.doc(this.postDetail.puzzle_id)
					.collection('m_puzzle_piece')
					.doc(this.postDetail.piece_id)
					.get();

				await puzzlePieceDocumentSnapshot.ref.update({
					search_text: {
						...puzzlePieceDocumentSnapshot.data().search_text,
						...{
							post_content: values.message,
							post_title: values.title
						}
					},
					updated_at: getFieldValue().serverTimestamp()
				});

				await this.$router.push({
					name: 'posts_detail',
					params: { id: this.postDetail.id }
				});
			} catch (error) {
				this.$toast('エラーが発生しました。', {
					type: 'error'
				});
				this.isSubmittingForm = false;
			}
		}
	}
};
</script>
